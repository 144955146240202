import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';

import 'country-flag-icons/3x2/flags.css';
import {
  TextInputProps,
  TextInput,
  Select,
  ComboboxLikeRenderOptionInput,
  ComboboxItem,
  Group,
  OptionsFilter,
} from '@any-ui-react/core';
import {
  CountryCode,
  formatIncompletePhoneNumber,
  getCountries,
  isSupportedCountry,
} from 'libphonenumber-js';

import { CountriesEnum, LanguageUtils } from '~anyx/shared/utils';

import { formatPhone, getIsd } from './InputPhone.utils';

type PhoneDataOption = {
  value: string;
  isd: string;
  language: string;
  country: string;
  label: string;
};

export type PhoneDataChange = {
  countryCode?: CountryCode;
  isd?: string;
  number: string;
};

export type PhoneData = {
  countryCode?: string;
  isd?: string | null;
  number?: string | null;
};

export type InputPhoneProps = Omit<TextInputProps, 'value' | 'onChange' | 'defaultValue'> & {
  language?: string;
  // override
  defaultValue?: PhoneData | null;
  value?: PhoneData | null;
  onChange?: (data: PhoneDataChange) => void;
  exclude?: CountryCode[];
};

export const InputPhone = forwardRef<HTMLInputElement, InputPhoneProps>(
  (
    {
      language = LanguageUtils.getTwoLettersCode(LanguageUtils.getCurrentLanguage()),
      disabled,
      error,
      value,
      defaultValue,
      onChange,
      exclude = [],
      ...rest
    }: InputPhoneProps,
    ref
  ) => {
    const [state, setState] = useState<PhoneData>(formatPhone(value || defaultValue));

    const countries = useMemo(() => {
      return getCountries()
        .filter((country) => !exclude.includes(country))
        .map((country) => ({
          value: country,
          isd: getIsd(country),
          language,
          country,
          label: `(${getIsd(country)})`,
        }));
    }, [exclude, language]);

    const renderSelectOption = (item: ComboboxLikeRenderOptionInput<ComboboxItem>) => {
      return (
        <div>
          <Group>
            <span className={`flag:${item.option.value}`} />
            <div className="text-xs">{item.option.label}</div>
          </Group>
        </div>
      );
    };

    const handleReformating = useCallback(() => {
      setState(formatPhone(state));
    }, [state]);

    useEffect(() => {
      setState(formatPhone(value || defaultValue));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value?.countryCode, defaultValue?.countryCode]);

    useEffect(() => {
      onChange?.(state as PhoneDataChange);
    }, [onChange, state]);

    const optionsFilter: OptionsFilter = ({ options, search }) => {
      return (options as PhoneDataOption[]).filter((option) => {
        return `${new Intl.DisplayNames([language || 'en'], { type: 'region' }).of(
          option['country']
        )} ${option['country']} ${option['isd']}`
          .toLowerCase()
          .includes(search);
      });
    };

    return (
      <TextInput
        ref={ref}
        {...rest}
        classNames={{
          input: 'pl-[6.75rem]',
          section: 'w-auto',
        }}
        onBlur={handleReformating}
        disabled={disabled || !state?.isd}
        value={state?.number || ''}
        error={error}
        onChange={(e) => {
          setState({
            countryCode: state?.countryCode,
            isd: state?.isd,
            number: isSupportedCountry(state?.countryCode || '')
              ? formatIncompletePhoneNumber(e.target.value || '', state?.countryCode as CountryCode)
              : e.target.value,
          });
        }}
        leftSection={
          <Select
            searchable
            filter={optionsFilter}
            data={countries}
            value={state?.countryCode}
            renderOption={renderSelectOption}
            onChange={(e) => {
              const newValue = formatPhone({
                countryCode: e as CountriesEnum,
                isd: e,
                number: isSupportedCountry(e as CountriesEnum)
                  ? formatIncompletePhoneNumber(
                      state.number || '',
                      state?.countryCode as CountryCode
                    )
                  : state.number,
              });
              setState(newValue);
            }}
            rightSectionProps={{
              className: 'w-full justify-end pr-[0.5rem] pointer-events-none',
            }}
            leftSection={<span className={`flag:${state.countryCode}`} />}
            leftSectionProps={{
              className: 'flex item-center',
            }}
            classNames={{
              root: 'w-[6.25rem] h-[30px]',
              wrapper: 'flex items-center bg-gray-0 h-[30px]',
              input: 'border-0 bg-transparent shadow-non border border-r border-gray-0',
              dropdown: 'min-w-[8.125rem]',
            }}
            comboboxProps={{
              position: 'bottom-start',
            }}
          />
        }
      />
    );
  }
);
