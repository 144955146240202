import { gql } from '~anyx/common/graphql';

export const HOME_REPORT_TABLE = gql(/* GraphQL */ `
  query HomeReportTable($filter: SaleChannelReportTableFilterInput!) {
    saleChannelReportTable(filter: $filter) {
      currency
      list {
        advertisingCost
        advertisingRevenue
        avgOrderValues
        commissionFee
        cvr
        discountRate
        discounts
        grossProfit
        grossProfitRate
        grossSales
        netSales
        totalSales
        cancelAndOrRefund
        marginalProfit
        marginalProfitRate
        merchandiseCost
        metric
        orders
        roas
        sessions
        shippingFee
      }
      totalItems
    }
  }
`);
