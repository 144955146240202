import { gql } from '~anyx/common/graphql';

export const WORKSPACE_SELECT = gql(/* GraphQL */ `
  query WorkspaceSelect(
    $pageNumber: Int!
    $pageSize: Int!
    $filter: MdStoreFilterInput
    $sortBy: MdStoreSortByInput
  ) {
    MdStores(pageNumber: $pageNumber, pageSize: $pageSize, filter: $filter, sortBy: $sortBy) {
      length
      limit
      start
      total
      items {
        name
        id
      }
    }
  }
`);
