import { useCallback, useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';
import { useInterval } from '@mantine/hooks';
import dayjs from 'dayjs';


import { CHANNEL_SYNC } from '../graphql';

export const POLLING_INTERVAL = 5000;

export const useGetBannersInterval = ({ storeId }: { storeId?: string }) => {
  const [getChannelSyncs, { data }] = useLazyQuery(CHANNEL_SYNC);
  const fetchChannelSyncs = useCallback(() => {
    if (!storeId) return;
    getChannelSyncs({
      variables: {
        after: dayjs().subtract(POLLING_INTERVAL, 'millisecond').toISOString(),
        masterDataStoreId: storeId,
      },
    });
  }, [getChannelSyncs, storeId]);

  const interval = useInterval(() => fetchChannelSyncs(), POLLING_INTERVAL);

  useEffect(() => {
    fetchChannelSyncs();
    interval.start();
    return interval.stop;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    data,
  };
};
