import { Action, MarketplaceSaleChannelType, ChannelSyncsQuery, SaleChannelSyncStatus } from '~anyx/common/graphql';

import { BannerType, SyncBannerAction, Syncs } from './SyncBanner';

export const getRelevantMarketplace = (
  _: MarketplaceSaleChannelType[],
  currentSyncs: ChannelSyncsQuery['saleChannelSyncs']['saleChannelSyncs'],
  filterBy: (banner: ChannelSyncsQuery['saleChannelSyncs']['saleChannelSyncs'][0]) => boolean
) => {
  const marketplaces = new Set();
  currentSyncs
    .filter(filterBy)
    .map(({ saleChannelTypes }) => saleChannelTypes)
    .flat()
    .forEach((channel) => marketplaces.add(channel));
  return marketplaces;
};

export const getSaleChannelSyncStatus = (
  prevSync: Syncs,
  action: SyncBannerAction,
  items: ChannelSyncsQuery['saleChannelSyncs']['saleChannelSyncs']
) => {
  const pending = getRelevantMarketplace(
    prevSync[action][SaleChannelSyncStatus.PENDING],
    items,
    (sync) => sync.status === SaleChannelSyncStatus.PENDING
  );

  const success = getRelevantMarketplace(
    prevSync[action][SaleChannelSyncStatus.SUCCESS],
    items,
    (sync) =>
      // We do not want to show success banner for initial sync
      sync.action !== Action.INITIAL_SYNC && sync.status === SaleChannelSyncStatus.SUCCESS
  );

  success.forEach((saleChannelType) => pending.delete(saleChannelType));

  return {
    ...prevSync,
    [action]: {
      [SaleChannelSyncStatus.SUCCESS]: Array.from(success),
      [SaleChannelSyncStatus.PENDING]: Array.from(pending),
    },
  };
};

export const clearStatus = (
  prevState: Syncs,
  status: keyof typeof BannerType,
  action: SyncBannerAction
) => {
  return {
    ...prevState,
    [action]: {
      ...prevState[action],
      [SaleChannelSyncStatus[status]]: [],
    },
  };
};
