import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { ServerParseError } from '@apollo/client';
import { User } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import useAsyncEffect from 'use-async-effect';

import { useAuthCtx } from '~anyx/common/auth';
import { MdUserRole, SelfDetailsQuery } from '~anyx/common/graphql';
import { FirebaseHelpers } from '~anyx/external/firebase';
import { LoadWrapper } from '~anyx/shared/ui';
import {
  CurrencyUtils,
  LanguageUtils,
  LocaleUtils,
  TimezoneUtils,
  useEnvironmentStore,
} from '~anyx/shared/utils';

import { useSelfDetails } from './operation';
import { SelfContext, defaultSelf } from './SelfContext';

export const SelfProvider = ({
  children,
  unavailableLink,
  permissionPath,
}: {
  children: React.ReactNode | React.ReactNode[];
  unavailableLink: string;
  permissionPath: string;
}) => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const pseudoLanguage = useEnvironmentStore((state) => state.pseudoLanguage);
  const { authenticated, user, logout } = useAuthCtx();

  const { loading, selfDetails, error, refetch } = useSelfDetails(authenticated);

  const [isSet, setIsSet] = useState<boolean>(false);

  const getSelf = useCallback(
    (anyXUser: SelfDetailsQuery['MdSelf'] | undefined, auth0User: User | undefined) => ({
      ...defaultSelf.self,
      ...anyXUser,
      email: auth0User?.email || '',
      picture: auth0User?.picture,
      name: LanguageUtils.localizeName(
        anyXUser?.firstName,
        anyXUser?.lastName ?? undefined,
        LanguageUtils.fromI18nLanguage(i18n.language)
      ),
    }),
    [i18n.language]
  );

  const self = useMemo(() => getSelf(selfDetails, user), [selfDetails, getSelf, user]);

  // User Settings
  useAsyncEffect(async () => {
    const { email, role, id, accounts } = self;

    // Do not reset language when going in crowdin in context mode
    // Since we dont store pseudo language in BE, we need to prevent the reset
    // in FE only.
    if (i18n.language !== pseudoLanguage) {
      LocaleUtils.setUserLocale(self?.languageId);
      LanguageUtils.setLanguage(LanguageUtils.fromI18nLanguage(self?.languageId));
    }

    TimezoneUtils.setTimezone(self?.timezoneId);
    CurrencyUtils.setCurrency(self?.currencyId);

    Sentry.setUser({
      email,
      id,
      role,
    });

    FirebaseHelpers.setUser(id, {
      role,
      account:
        role === MdUserRole.MERCHANT
          ? {
              accountId: accounts?.[0]?.id || '',
              name: accounts?.[0]?.name || '',
            }
          : undefined,
      isInternalTester: !!user?.[permissionPath]?.includes('category:internal_tester'),
      isInternalUser: !!user?.[permissionPath]?.includes('category:internal_user'),
    });

    setIsSet(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [self]);

  // Logout user in case of 401 or UNAUTHORIZED response to `getSelf` This should technically happen
  // Only when the user reload the page and the token / refresh token is expired
  useEffect(() => {
    if (error) {
      if (Object.prototype.hasOwnProperty.call(error?.networkError || {}, 'response')) {
        const serverError = error?.networkError as ServerParseError;

        if (serverError.statusCode === 401) {
          logout();
          return;
        }
      }
      if (error?.message === 'UNAUTHORIZED') {
        logout();
        return;
      }

      if (window.location.pathname !== unavailableLink) {
        navigate(unavailableLink);
      }
    }
  }, [error, logout, navigate, unavailableLink]);

  return (
    <SelfContext.Provider
      value={{
        self,
        refetch,
        isSet,
      }}
    >
      <LoadWrapper loading={loading} loaderId="self" className="flex h-screen">
        {children}
      </LoadWrapper>
    </SelfContext.Provider>
  );
};
