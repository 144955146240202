import { gql } from '~anyx/common/graphql';

export const DDI_HOME_REPORT_PIE_CHART = gql(/* GraphQL */ `
  query DDIHomeReportPieChart($input: DdiHomeReportInput!) {
    ddiHomeReportPieChart(input: $input) {
      currency
      grossSales {
      saleChannelType
      value
    }
    netSales {
      saleChannelType
      value
    }
    orders {
      saleChannelType
      value
    }
    }
  }
`);
