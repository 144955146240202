import { useMemo } from 'react';

import { useQuery } from '@apollo/client';

import { GET_STORE_UNREAD_COUNT } from './graphql';

interface AnyChatUnreadBadgeProps {
  storeId?: string;
}

export const AnyChatUnreadBadge = ({ storeId }: AnyChatUnreadBadgeProps) => {
  const { data } = useQuery(GET_STORE_UNREAD_COUNT, {
    skip: !storeId,
    variables: {
      input: {
        id: storeId as string,
      },
    },
    pollInterval: 20000,
  });

  const count: string | null = useMemo(() => {
    const unreadCount = data?.AnyChatStore?.chatCount.unreadCount || 0;

    if (unreadCount === 0) {
      return null;
    }

    if (unreadCount >= 99) {
      return '99+';
    }

    return `${unreadCount}`;
  }, [data?.AnyChatStore?.chatCount.unreadCount]);

  if (!count) {
    return null;
  }

  return (
    <div className="bg-info text-3xs grid w-max min-w-[1.25rem] shrink-0 place-items-center rounded-full px-1 leading-10 text-white">
      {count}
    </div>
  );
};
