import { useMemo } from 'react';

import { useQuery } from '@apollo/client';

import { MODAL_PAGE_SIZE } from '~anyx/shared/utils';

import { VariantSelectorFiltersType } from '../components/variant-selector/filter';
import { PRODUCT_SET_VARIANTS_VARIANT_SELECTOR } from '../graphql';
import { VariantSetDetail } from '../type';

export const useProductSetVariantsVariantSelector = ({
  filters,
}: {
  filters: VariantSelectorFiltersType;
}) => {
  const { page, ...current } = filters;

  const { data, loading, error } = useQuery(PRODUCT_SET_VARIANTS_VARIANT_SELECTOR, {
    variables: {
      pageNumber: page,
      pageSize: MODAL_PAGE_SIZE,
      filter: {
        ...current,
      },
    },
  });

  const products: VariantSetDetail[] = useMemo(() => {
    return (data?.productVariantsForProductSet?.productVariants || []).map((item) => ({
      productVariantId: item.productVariantId,
      productName: item.productName,
      variantName: item.variantName,
      media: item.media || '',
      productCode: item.productCode,
      quantity: 0,
    }));
  }, [data?.productVariantsForProductSet.productVariants]);

  return { products, total: data?.productVariantsForProductSet.total || 0, loading, error };
};
