import { forwardRef, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import { Combobox, InputBase, MultiSelectProps, useCombobox } from '@any-ui-react/core';

import { CloseIcon } from '~anyx/shared/icons';

import { useProductTag } from './operation';
import classes from './ProductTagInput.module.css';

type ProductTagInputProps = {
  masterDataStoreId: string;
  masterDataAccountId?: string;
  onChange: (tags: string[]) => void;
  value: string[];
} & Omit<MultiSelectProps, 'data' | 'value' | 'onChange'>;

interface MantineValueComponentProps {
  value: string;
  onRemove: () => void;
}

export const ProductTagInput = forwardRef<HTMLInputElement, ProductTagInputProps>(
  ({ masterDataAccountId, masterDataStoreId, onChange, value }, ref) => {
    const { t } = useTranslation();
    const tagContainerRef = useRef<HTMLDivElement>(null);
    const [newOption, setNewOption] = useState<string[]>([]);
    const { tags } = useProductTag({ masterDataAccountId, masterDataStoreId });

    const combobox = useCombobox({
      onDropdownClose: () => combobox.resetSelectedOption(),
    });

    const [search, setSearch] = useState('');

    const exactOptionMatch = useMemo(() => {
      return [...tags, ...newOption].some((tag) => tag === search);
    }, [tags, newOption, search]);

    const filteredOptions = useMemo(() => {
      return [...tags, ...newOption].filter((tag) =>
        tag.toLowerCase().includes(search.toLowerCase().trim())
      );
    }, [tags, newOption, search]);

    const options = filteredOptions.map((tag) => (
      <Combobox.Option value={tag} key={tag}>
        {tag}
      </Combobox.Option>
    ));

    const handleCreateOption = () => {
      if (!exactOptionMatch && search.trim().length > 0) {
        setNewOption((prevOptions) => [...prevOptions, search]);
        onChange([...value, search]);
      }
    };

    const handleRemoveTag = (tagToRemove: string) => {
      onChange(value.filter((tag) => tag !== tagToRemove));
    };

    const ValueComponent = ({ value, onRemove }: MantineValueComponentProps) => {
      if (!tagContainerRef.current) {
        return null;
      }

      return ReactDOM.createPortal(
        <div className="bg-gray-2 text-2xs user-select-none inline-flex cursor-default items-center rounded p-1 pl-2">
          {value}
          <button
            type="button"
            onMouseDown={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onClick={onRemove}
          >
            <CloseIcon type="button" className="ml-1 h-3 w-3 cursor-pointer" />
          </button>
        </div>,
        tagContainerRef.current
      );
    };

    return (
      <>
        <Combobox
          store={combobox}
          withinPortal={false}
          onOptionSubmit={(val) => {
            if (val === '$create') {
              handleCreateOption();
            } else {
              onChange([...value, val]);
            }
            setSearch('');
            combobox.closeDropdown();
          }}
        >
          <Combobox.Target>
            <InputBase
              ref={ref}
              value={search}
              onChange={(event) => {
                setSearch(event.currentTarget.value);
                combobox.openDropdown();
                combobox.updateSelectedOptionIndex();
              }}
              onClick={() => combobox.openDropdown()}
              onFocus={() => combobox.openDropdown()}
              onBlur={() => {
                combobox.closeDropdown();
                setSearch('');
              }}
              placeholder={t('marketplace.page.product.edit.card.tags.placeholder', {
                ns: 'marketplace',
              })}
              rightSection={<Combobox.Chevron />}
            />
          </Combobox.Target>

          <Combobox.Dropdown className={classes['dropdown']}>
            <Combobox.Options>
              {options}
              {!exactOptionMatch && search.trim().length > 0 && (
                <Combobox.Option value="$create">+ Create {search}</Combobox.Option>
              )}
            </Combobox.Options>
          </Combobox.Dropdown>
        </Combobox>

        <div ref={tagContainerRef} className="flex flex-wrap gap-2 pt-2">
          {value.map((tag) => (
            <ValueComponent key={tag} value={tag} onRemove={() => handleRemoveTag(tag)} />
          ))}
        </div>
      </>
    );
  }
);
