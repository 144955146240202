import { gql } from '~anyx/common/graphql';

export const HOME_REPORT_SUMMARY = gql(/* GraphQL */ `
  query HomeReportSummary($filter: SaleChannelReportSummaryFilterInput!) {
    saleChannelReportSummary(filter: $filter) {
      comparisonSummary {
        avgOrderValues {
          current
          diff
          isPercentage
          percentage
        }
        cvr {
          current
          diff
          isPercentage
          percentage
        }
        grossProfit {
          current
          diff
          isPercentage
          percentage
        }
        grossProfitRate {
          current
          diff
          isPercentage
          percentage
        }
        grossSales {
          current
          diff
          isPercentage
          percentage
        }
        marginalProfit {
          current
          diff
          isPercentage
          percentage
        }
        marginalProfitRate {
          current
          diff
          isPercentage
          percentage
        }
        orders {
          current
          diff
          isPercentage
          percentage
        }
        roas {
          current
          diff
          isPercentage
          percentage
        }
        sessions {
          current
          diff
          isPercentage
          percentage
        }
      }
      currency
      tableSummary {
        advertisingCost
        advertisingRevenue
        avgOrderValues
        commissionFee
        cvr
        discountRate
        discounts
        grossProfit
        grossProfitRate
        grossSales
        netSales
        totalSales
        cancelAndOrRefund
        marginalProfit
        marginalProfitRate
        merchandiseCost
        orders
        roas
        sessions
        shippingFee
      }
    }
  }
`);
