import { HttpResponse, delay, graphql } from 'msw';

import { HomeReportPieChartQuery } from '~anyx/common/graphql';

export const MockPieChart = {
  DEFAULT: {
    reportHomeSaleChannelRevenue: {
      __typename: 'HomeSaleChannelRevenueReportView',
      currency: 'USD',
      data: [
        {
          __typename: 'HomeReportSaleChannelRevenuePayload',
          grossSales: '1513704',
          saleChannelType: 'Shopify',
        },
        {
          __typename: 'HomeReportSaleChannelRevenuePayload',
          grossSales: '272029',
          saleChannelType: 'Lazada',
        },
        {
          __typename: 'HomeReportSaleChannelRevenuePayload',
          grossSales: '271015',
          saleChannelType: 'Rakuten',
        },
        {
          __typename: 'HomeReportSaleChannelRevenuePayload',
          grossSales: '184319',
          saleChannelType: 'TikTok',
        },
        {
          __typename: 'HomeReportSaleChannelRevenuePayload',
          grossSales: '179816',
          saleChannelType: 'Yahoo',
        },
        {
          __typename: 'HomeReportSaleChannelRevenuePayload',
          grossSales: '176837',
          saleChannelType: 'Tokopedia',
        },
        {
          __typename: 'HomeReportSaleChannelRevenuePayload',
          grossSales: '152772',
          saleChannelType: 'AmazonSellerCentral',
        },
        {
          __typename: 'HomeReportSaleChannelRevenuePayload',
          grossSales: '141390',
          saleChannelType: 'Shopee',
        },
        {
          __typename: 'HomeReportSaleChannelRevenuePayload',
          grossSales: '34160',
          saleChannelType: 'Momo',
        },
        {
          __typename: 'HomeReportSaleChannelRevenuePayload',
          grossSales: '9282',
          saleChannelType: 'Qoo10',
        },
        {
          __typename: 'HomeReportSaleChannelRevenuePayload',
          grossSales: '7462',
          saleChannelType: 'Zozotown',
        },
        {
          __typename: 'HomeReportSaleChannelRevenuePayload',
          grossSales: '3967',
          saleChannelType: 'Magaseek',
        },
        {
          __typename: 'HomeReportSaleChannelRevenuePayload',
          grossSales: '2494',
          saleChannelType: 'Manual',
        },
      ],
    },
  },
} satisfies Record<string, HomeReportPieChartQuery>;

export const MockPieChartQuery = {
  default: () => {
    return graphql.query('PieChart', async (_) => {
      await delay(500);
      return HttpResponse.json({ data: MockPieChart.DEFAULT });
    });
  },
};
