import { useCallback } from 'react';

import { useMutation } from '@apollo/client';

import { MdUpdateUserInput, AlertType, useApiTranslationMapper } from '~anyx/common/graphql';
import { toast } from '~anyx/shared/ui';

import { UPDATE_SELF } from '../graphql';

export const useUpdateSelf = () => {
  const { getErrorMessage, getTranslationKey } = useApiTranslationMapper();
  const [updateSelf, { loading }] = useMutation(UPDATE_SELF);

  const handleUpdateSelf = useCallback(
    async ({
      id,
      currencyId,
      email,
      firstName,
      languageId,
      lastName,
      timezoneId,
      accessAllStores,
      canManageUsers,
      password,
      accountIds,
      storeIds,
      availableModules,
    }: MdUpdateUserInput) => {
      try {
        const { data } = await updateSelf({
          variables: {
            input: {
              id,
              currencyId,
              email,
              firstName,
              languageId,
              lastName,
              accountIds,
              storeIds,
              timezoneId,
              password,
              accessAllStores,
              canManageUsers,
              availableModules,
            },
          },
        });

        toast.success(
          getTranslationKey({
            namespace: 'gql',
            type: AlertType.SUCCESS,
            path: 'user',
            message: 'updateUser',
          })
        );

        return data;
      } catch (e: unknown) {
        toast.error(
          getTranslationKey({
            namespace: 'gql',
            type: AlertType.ERROR,
            path: 'user',
            message: getErrorMessage(e),
          })
        );

        return null;
      }
    },
    [updateSelf, getTranslationKey, getErrorMessage]
  );

  return { handleUpdateSelf, loading };
};
