import { gql } from '~anyx/common/graphql';

export const ACCOUNT_SELECT = gql(/* GraphQL */ `
  query MdAccountSelect($id: Long!) {
    MdAccount(id: $id) {
      id
      name
    }
  }
`);
