import { forwardRef, ReactElement, RefAttributes } from 'react';

import {
  AsyncPaginateSelectProps,
  GroupBase,
  LoadOptions,
  SelectForwardRef,
  SelectInstance,
} from '@any-ui-react/select';
import { useLazyQuery } from '@apollo/client';

import { MdStoreSortByFieldEnum } from '~anyx/common/graphql';
import { useSelfCtx } from '~anyx/common/self';
import { useWorkspaceCtx } from '~anyx/common/workspace';
import { LazySelectV2 } from '~anyx/shared/ui';

import { useSwitchWorkspace } from '../../../hooks';

import { Option } from './components';
import { WORKSPACE_SELECT } from './graphql';

export type WorkspaceOption = {
  label: string;
  value: string;
};

type WorkspaceSelectAdditional = {
  pageNumber: number;
};

export type WorkspaceSelectProps = Omit<
  AsyncPaginateSelectProps<WorkspaceOption, GroupBase<WorkspaceOption>, WorkspaceSelectAdditional>,
  'loadOptions'
> & {
  loadOptions?: LoadOptions<WorkspaceOption, GroupBase<WorkspaceOption>, WorkspaceSelectAdditional>;
};

export type WorkspaceSelect = (
  props: WorkspaceSelectProps & RefAttributes<SelectInstance<WorkspaceSelectProps>>
) => ReactElement;

export const WorkspaceSelect = forwardRef(
  (
    { loadOptions, components, ...rest }: WorkspaceSelectProps,
    ref: SelectForwardRef<WorkspaceOption>
  ) => {
    const {
      self: { accountIds },
    } = useSelfCtx();

    const value = useWorkspaceCtx((state) => {
      const { storeName, storeId } = state.workspace || {};
      return storeName && storeId ? { label: storeName, value: storeId } : undefined;
    });
    const { switchWorkspace } = useSwitchWorkspace();

    const [fetchWorkspaceOptions] = useLazyQuery(WORKSPACE_SELECT);

    const WorkspaceSelectLoadOptions: LoadOptions<
      WorkspaceOption,
      GroupBase<WorkspaceOption>,
      WorkspaceSelectAdditional
    > = async (keyword, loadedOptions, additional) => {
      try {
        const { pageNumber = 1 } = additional || {};
        const loadedOptionsLength = loadedOptions?.length || 0;

        const { data } = await fetchWorkspaceOptions({
          variables: {
            pageNumber,
            pageSize: 20,
            filter: {
              ...(accountIds?.length > 0 && {
                accountIds: accountIds,
              }),
              ...(keyword && { keyword }),
            },
            sortBy: {
              descending: false,
              field: MdStoreSortByFieldEnum.NAME,
            },
          },
        });

        const options =
          data?.MdStores.items.map(({ id, name }) => ({
            label: name,
            value: id,
          })) || [];

        const hasMore = !!(
          data?.MdStores.total && loadedOptionsLength + options.length < data?.MdStores?.total
        );

        return {
          options,
          hasMore,
          additional: {
            pageNumber: pageNumber + 1,
          },
        };
      } catch (error) {
        return {
          options: [],
          hasMore: false,
          additional,
        };
      }
    };

    return (
      <LazySelectV2
        ref={ref}
        className="[&>.any-select\_\_control]:h-10 [&>.any-select\_\_control]:font-semibold"
        loadOptions={loadOptions ? loadOptions : WorkspaceSelectLoadOptions}
        components={{ ...components, Option }}
        isClearable={false}
        debounceTimeout={300}
        value={value}
        onChange={(option) => {
          if (!option) return;
          switchWorkspace({ storeId: option.value });
        }}
        {...rest}
      />
    );
  }
) as WorkspaceSelect;
