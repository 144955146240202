import { useQuery } from '@apollo/client';

import { useCrmConnectionErrorCount } from '~anyx/integration-crm/shared';

import { GET_INACTIVE_SALE_CHANNELS } from '../graphql';

export const useGetInactiveIntegrations = ({ storeId = '' }: { storeId?: string }) => {
  const { data, loading, error } = useQuery(GET_INACTIVE_SALE_CHANNELS, {
    skip: !storeId,
    variables: { storeId: storeId },
  });
  const crmCount = useCrmConnectionErrorCount({
    storeId: storeId,
  });

  return {
    inactiveIntregrationCount: (data?.onboarding?.inactiveSaleChannelCount || 0) + crmCount,
    loading,
    error,
  };
};
