import { gql } from '~anyx/common/graphql';

export const CHECK_ONBOARDING = gql(/* GraphQL */`
  query CheckOnboarding($input: OnboardingInput!, $storeId: Long!) {
    hasConnectedSaleChannel(input: $input)
    inactiveIntegrationCount(input: $input)
    hasAdvertisingConnection(input: $input)
    hasAnalyticsReport(input: $input)
    settingGuide(input: $input) {
      hasShopifyChannel
      hasInputUnitCost
      hasConnectedDatasourceChannel
      hasSaleChannelCostIndex
    }
    onboarding(storeId: $storeId) {
      hasRegisteredOrder
    }
  }
`);
