import { gql } from '~anyx/common/graphql';

export const SELF_DETAILS = gql(/* GraphQL */`
  query SelfDetails {
    MdSelf {
      id
      accountIds
      accounts {
      id
      name
    }
    storeIds
    firstName
    lastName
    languageId
    currencyId
    timezoneId
    role
    email
    availableModules
    canManageUsers
    accessAllStores
    }
  }
`);
