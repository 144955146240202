import { ReactNode } from 'react';

interface TitledIconProps {
  icon: React.ReactNode;
  title?: ReactNode | null;
  text?: string | null;
  orientation?: 'horizontal' | 'vertical';
}

export const TitledIcon = ({
  icon,
  text = null,
  title = null,
  orientation = 'vertical',
}: TitledIconProps) => {
  return orientation === 'vertical' ? (
    <div className="w-fit">
      <div className="flex gap-1">
        {icon}
        {title}
      </div>
      {text && (
        <div className="bg-gray-7 text-4xs flex items-center justify-center rounded-xl font-bold uppercase text-white">
          {text.substring(0, 2)}
        </div>
      )}
    </div>
  ) : (
    <div className="flex flex-1 items-center p-3">
      {icon && <div className="first-child:w-8 first-child:h-8 mr-1">{icon}</div>}
      <div className="flex gap-1">
        {title}
        {text && (
          <span className="bg-gray-7 text-4xs items-center justify-center self-end rounded-xl px-2 font-bold uppercase text-white">
            {text}
          </span>
        )}
      </div>
    </div>
  );
};
