import { gql } from '~anyx/common/graphql';

export const ACCOUNTS_SELECT = gql(/* GraphQL */ `
  query MdAccountsSelect(
    $pageNumber: Int!
    $pageSize: Int!
    $filter: MdAccountFilterInput
    $sortBy: MdAccountSortByInput
  ) {
    MdAccounts(pageNumber: $pageNumber, pageSize: $pageSize, filter: $filter, sortBy: $sortBy) {
      total
      start
      limit
      length
      items {
        name
        id
      }
    }
  }
`);
