import { useQuery } from '@apollo/client';

import { HomeFilterInputFilterType } from '../components';
import { HOME_REPORT_PRODUCT_TABLE } from '../graphql';

interface UseHomeReportProductTableProps {
  filters: HomeFilterInputFilterType;
  skip: boolean;
}

export const useHomeReportProductTable = ({ filters, skip }: UseHomeReportProductTableProps) => {
  const {
    dimensions: _dimension,
    metric: _metric,
    adsMetricsType: _adsMetricsType,
    tab: _tab,
    ...filterInput
  } = filters;
  const { data, loading, error, refetch } = useQuery(HOME_REPORT_PRODUCT_TABLE, {
    variables: {
      filter: filterInput,
    },
    skip,
  });

  return {
    reportProductTable: data?.reportHomeTop5Products,
    loading,
    error,
    refetch,
  };
};
