import { useQuery } from '@apollo/client';

import { HomeFilterInputFilterType } from '../components';
import { HOME_REPORT_TABLE } from '../graphql';

interface UseHomeReportTableProps {
  filters: HomeFilterInputFilterType;
  skip: boolean;
}

export const useHomeReportTable = ({ filters, skip }: UseHomeReportTableProps) => {
  const { metric: _metric, chartTab: _chartTab, ...filterInput } = filters;
  const { data, loading, error } = useQuery(HOME_REPORT_TABLE, {
    variables: {
      filter: filterInput,
    },
    skip,
  });

  return {
    reportTable: data?.saleChannelReportTable,
    loading,
    error,
  };
};
