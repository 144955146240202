import { gql } from '~anyx/common/graphql';

export const HOME_REPORT_CHART = gql(/* GraphQL */ `
  query HomeReportChart($filter: SaleChannelReportTableFilterInput!) {
    saleChannelReportChart(filter: $filter) {
      channels
      currency
      list {
        advertisingCost
        advertisingRevenue
        avgOrderValues
        channel
        commissionFee
        cvr
        dailySales
        day
        discountRate
        discounts
        dow
        grossProfit
        grossProfitRate
        grossSales
        netSales
        totalSales
        cancelAndOrRefund
        marginalProfit
        marginalProfitRate
        marketplaceCalendarEvents {
          endDate
          id
          masterDataStoreId
          saleChannelCountry
          saleChannelType
          startDate
          target {
            currency
            revenue
          }
          title
        }
        masterDataStoreId
        merchandiseCost
        orders
        roas
        sessions
        shippingFee
        storeName
        yearMonth
        yearWeek
      }
      stores {
        id
        name
      }
        totalItems
      }
    }
`);
