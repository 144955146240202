import { useState, useEffect, useRef } from 'react';

import { useLazyQuery } from '@apollo/client';

import { PRODUCT_TAGS_SELECT } from '../graphql';

export const useProductTagSelect = ({
  storeIds,
  accountId,
}: {
  storeIds: string[] | null;
  accountId: string | null;
}) => {
  const [tags, setTags] = useState<string[]>([]);

  const [fetchTags] = useLazyQuery(PRODUCT_TAGS_SELECT);

  const prevStoreIds = useRef<string[] | null>(null);

  useEffect(() => {
    if (prevStoreIds.current?.every((value) => storeIds?.includes(value))) {
      return;
    }
    prevStoreIds.current = storeIds;

    const getTags = async () => {
      const { data } = await fetchTags({
        variables: {
          filter: {
            ...(accountId ? { masterDataAccountId: accountId } : {}),
            ...((storeIds || []).length > 0 ? { masterDataStoreIds: storeIds } : {}),
          },
        },
      });
      setTags([...(data?.tagsV2 || [])]);
    };
    getTags();
  }, [accountId, fetchTags, storeIds]);

  return { tags };
};
