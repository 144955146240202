import { useCallback, useEffect, useState } from 'react';
import { useMatches, useNavigate } from 'react-router';

import { useLazyQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import useAsyncEffect from 'use-async-effect';
import z from 'zod';
import { shallow } from 'zustand/shallow';

import {
  ChatType,
  MdAccountProduct,
  MdStoreSortByFieldEnum,
  MdUserRole,
  WorkspaceDataFragment,
} from '~anyx/common/graphql';
import { useSelfCtx } from '~anyx/common/self';
import { FirebaseHelpers } from '~anyx/external/firebase';
import { LoadWrapper } from '~anyx/shared/ui';
import { AsyncOperationState } from '~anyx/shared/utils';

import { INIT_WORKSPACE, VALIDATE_WORKSPACE } from '../graphql';
import { Workspace, WorkspaceMode } from '../models';

import { setWorkspace } from './actions';
import { useWorkspaceCtx } from './WorkspaceContext';

const parseWorkspaceData = (mdStore?: WorkspaceDataFragment | null): Workspace => {
  return z
    .object({
      storeName: z.string(),
      accountName: z.string(),
      storeId: z.string(),
      accountId: z.string(),
      accountProduct: z.nativeEnum(MdAccountProduct),
      crmWorkspace: z.object({
        isCrmEnabled: z.boolean().default(false),
        chats: z.array(z.nativeEnum(ChatType)).catch([]),
      }),
    })
    .parse({
      storeName: mdStore?.name,
      accountName: mdStore?.account?.name,
      storeId: mdStore?.id,
      accountId: mdStore?.account?.id,
      accountProduct: mdStore?.account?.product,
      crmWorkspace: {
        isCrmEnabled: mdStore?.crmStore?.isCrmEnabled,
        chats: (mdStore?.crmStore?.chats || []).map((c) => c.type),
      },
    });
};

const useValidateWorkspace = () => {
  const [validateWorkspaceQuery] = useLazyQuery(VALIDATE_WORKSPACE, {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  });

  const validateWorkspace = useCallback(
    async (storeId: string) => {
      const { data, error } = await validateWorkspaceQuery({
        variables: {
          mdStoreId: storeId,
        },
      });

      try {
        return parseWorkspaceData(data?.MdStore);
      } catch (_) {
        throw error || new Error('Failed to Validate workspace');
      }
    },
    [validateWorkspaceQuery]
  );

  return {
    validateWorkspace,
  };
};

export const useFirstWorkspace = ({ accountId }: { accountId?: string }) => {
  const [initWorkspaceQuery] = useLazyQuery(INIT_WORKSPACE, {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  });

  const getFirstWorkspace = useCallback(async () => {
    const { data, error } = await initWorkspaceQuery({
      variables: {
        pageNumber: 1,
        pageSize: 1,
        filter: {
          ...(accountId && {
            accountIds: [accountId],
          }),
        },
        sortBy: {
          descending: false,
          field: MdStoreSortByFieldEnum.NAME,
        },
      },
    });

    const hasStore = (data?.MdStores.items.length || 0) > 0;

    if (!hasStore && !error) {
      return undefined;
    }

    try {
      return parseWorkspaceData(data?.MdStores.items[0]);
    } catch (_) {
      throw error || new Error('Failed to get first workspace');
    }
  }, [initWorkspaceQuery, accountId]);

  return {
    getFirstWorkspace,
  };
};

const useInitScopedWorkspace = ({
  storeId,
  accountId,
  skip = false,
  onboardingRedirect,
}: {
  storeId?: string;
  accountId?: string;
  skip?: boolean;
  onboardingRedirect: string;
}) => {
  const navigate = useNavigate();

  const { getFirstWorkspace } = useFirstWorkspace({ accountId });
  const { validateWorkspace } = useValidateWorkspace();

  const [status, setStatus] = useState<AsyncOperationState>(AsyncOperationState.IDLE);
  useAsyncEffect(
    async (isMounted) => {
      if (skip) return;

      setStatus(AsyncOperationState.LOADING);
      let workspaceData: Workspace | undefined;

      try {
        if (!storeId) {
          workspaceData = await getFirstWorkspace();
        } else {
          workspaceData = await validateWorkspace(storeId);
        }
        setStatus(AsyncOperationState.SUCCESS);

        if (!isMounted()) return;

        setWorkspace(workspaceData);

        if (workspaceData === undefined) {
          navigate(onboardingRedirect);
        }
      } catch (error) {
        setStatus(AsyncOperationState.ERROR);
        if (!isMounted()) return;

        Sentry.captureException(error);

        navigate('/error/notfound');
      }
    },
    [getFirstWorkspace, navigate, skip, storeId, validateWorkspace, onboardingRedirect]
  );

  return { loading: status === AsyncOperationState.LOADING };
};

const useInitFreeWorkSpace = ({ skip, accountId }: { skip?: boolean; accountId?: string }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (skip) return;
    setLoading(true);

    if (accountId) {
      setWorkspace({
        accountId,
      });
    } else {
      setWorkspace(undefined);
    }

    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [accountId, skip]);

  return {
    loading,
  };
};

const uniqueStoreSessionSchema = z
  .object({
    storeName: z.string(),
    storeId: z.string(),
    accountName: z.string(),
    accountId: z.string(),
  })
  .transform(({ storeName, storeId, accountName, accountId }) => ({
    store_name: storeName,
    store_id: storeId,
    account_name: accountName,
    account_id: accountId,
  }));

const getUniqueStoreSessionData = (workspace?: Workspace) => {
  try {
    return uniqueStoreSessionSchema.parse(workspace);
  } catch (error) {
    return undefined;
  }
};

const useLogUniqueStoreSession = () => {
  const uniqueStoreSessionData = useWorkspaceCtx(
    (state) => getUniqueStoreSessionData(state.workspace),
    shallow
  );

  useEffect(() => {
    if (uniqueStoreSessionData) {
      FirebaseHelpers.logUniqueStoreSessionEvent(uniqueStoreSessionData);
    }
  }, [uniqueStoreSessionData]);
};

const useLogWorkspaceMode = () => {
  const isSet = useWorkspaceCtx((state) => state.isSet);
  const mode = useWorkspaceCtx((state) => (state.mode === WorkspaceMode.ON ? 'SCOPED' : 'FREE'));

  useEffect(() => {
    if (isSet) {
      FirebaseHelpers.logWorkspaceSwitchEvent(mode);
    }
  }, [isSet, mode]);
};

const usePathStoreId = () => {
  const matches = useMatches();
  const pathStoreIdParam = matches[0]?.params?.['workspaceMasterStoreDataId'];
  const isPathWithStoreId = z.coerce.number().safeParse(pathStoreIdParam);

  return isPathWithStoreId.success ? pathStoreIdParam : undefined;
};

export const WorkspaceProvider = ({
  onboardingRedirect,
  children,
}: {
  onboardingRedirect: string;
  children: (workspace?: Workspace, mode?: WorkspaceMode) => React.ReactNode;
}) => {
  useLogUniqueStoreSession();
  useLogWorkspaceMode();

  const workspace = useWorkspaceCtx((state) => state.workspace);
  const mode = useWorkspaceCtx((state) => state.mode);
  const {
    self: { accountIds, role },
    isSet,
  } = useSelfCtx();

  const pathWithStoreId = usePathStoreId();
  const isWorkspaceModeOn = pathWithStoreId !== undefined;
  const isWorkspaceSyncWithPath = useWorkspaceCtx(
    (state) => state.workspace?.storeId === pathWithStoreId
  );

  const { loading: scopedWorkspaceLoading } = useInitScopedWorkspace({
    skip: isWorkspaceSyncWithPath || !isWorkspaceModeOn || !isSet,
    storeId: pathWithStoreId,
    accountId: role === MdUserRole.MERCHANT ? accountIds[0] ?? undefined : undefined,
    onboardingRedirect,
  });

  const { loading: freeWorkspaceLoading } = useInitFreeWorkSpace({
    skip: isWorkspaceModeOn || !isSet,
    accountId: role === MdUserRole.MERCHANT ? accountIds[0] ?? undefined : undefined,
  });

  return (
    <LoadWrapper
      loading={scopedWorkspaceLoading || freeWorkspaceLoading}
      loaderId="workspace"
      className="min-w-screen absolute right-0 top-0 z-20 flex min-h-screen min-w-full justify-center overflow-hidden bg-white"
    >
      {children?.(workspace, mode)}
    </LoadWrapper>
  );
};
