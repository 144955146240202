import { gql } from '~anyx/common/graphql';

export const PROVINCES = gql(/* GRAPHQL */ `
  query MdProvinces($filter: MdProvinceFilterInput) {
    MdProvinces(filter: $filter) {
      id
      countryId
      name
      lname
      country {
        languageId
        }
      }
    }
`);
