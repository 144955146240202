import { gql } from '~anyx/common/graphql';

export const PRODUCT_VARIANT_VALUES_SELECT = gql(/* GRAPHQL */ `
  query ProductVariantValuesSelect($input: VariantValuesInput!) {
    variantValues(input: $input) {
      items {
        name
      }
      meta {
        length
        limit
        start
        total
      }
    }
  }
`);
