import { useQuery } from '@apollo/client';

import { HomeFilterInputFilterType } from '../components';
import { HOME_REPORT_PIE_CHART } from '../graphql';

interface UseHomeReportPieChartProps {
  filters: HomeFilterInputFilterType;
  skip: boolean;
}

export const useHomeReportPieChart = ({ filters, skip }: UseHomeReportPieChartProps) => {
  const {
    dimensions: _dimension,
    metric: _metric,
    adsMetricsType: _adsMetricsType,
    tab: _tab,
    ...filterInput
  } = filters;
  const { data, loading, error, refetch } = useQuery(HOME_REPORT_PIE_CHART, {
    variables: {
      filter: filterInput,
    },
    skip,
  });

  return {
    reportPieChart: data?.reportHomeSaleChannelRevenue,
    loading,
    error,
    refetch,
  };
};
