import { gql } from '~anyx/common/graphql';

export const SETTING_GUIDE_SALE_CHANNEL = gql(/* GraphQL */ `
  query SettingGuideSaleChannel($mdStoreId: Long!) {
    MdStore(id: $mdStoreId) {
      id
      channels {
        id
        type
        costIndex {
          saleChannelId
        }
      }
    }
  }
`);
