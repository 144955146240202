import { gql } from '~anyx/common/graphql';

export const CHANNEL_SYNC = gql(/* GRAPHQL */ `
    query ChannelSyncs($after: OffsetDateTime!, $masterDataStoreId: Long!) {
      saleChannelSyncs(after: $after, masterDataStoreId: $masterDataStoreId) {
        saleChannelSyncs {
        action
        saleChannelTypes
        status
        errors
      }
    }
  }
`);
