import { gql } from '~anyx/common/graphql';

export const GET_STORE_UNREAD_COUNT = gql(/* GraphQL */ `
  query GetStoreUnreadCount($input: AnyChatGetStoreRequest!) {
    AnyChatStore(input: $input) {
      chatCount {
        unreadCount
      }
    }
  }
`);
