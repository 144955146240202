import { gql } from '~anyx/common/graphql';

export const HOME_REPORT_PIE_CHART = gql(/* GraphQL */ `
  query HomeReportPieChart($filter: HomeReportFilterInput!) {
    reportHomeSaleChannelRevenue(filter: $filter) {
      currency
      data {
        grossSales
        masterDataStoreId
        saleChannelType
        storeName
      }
    }
  }
`);
