import { gql } from '~anyx/common/graphql';

export const STORE_SELECT = gql(/* GRAPHQL */ `
  query MdStoreSelect($mdStoreId: Long!) {
    MdStore(id: $mdStoreId) {
      id
      name
    }
  }
`);
