import { useQuery } from '@apollo/client';

import { SELF_DETAILS } from '../graphql';

export const useSelfDetails = (authenticated: boolean) => {
  const { loading, data, error, refetch } = useQuery(SELF_DETAILS, {
    skip: !authenticated,
  });

  return { loading, selfDetails: data?.MdSelf, error, refetch };
};
