import { useQuery } from '@apollo/client';

import { DdiHomeFilterInputFilterType } from '../components';
import { DDI_HOME_REPORT_PIE_CHART } from '../graphql';

interface UseDdiHomeReportPieChartProps {
  filters: DdiHomeFilterInputFilterType;
  skip: boolean;
}

export const useDdiHomeReportPieChart = ({ filters, skip }: UseDdiHomeReportPieChartProps) => {
  const { metric: _metric, ...filterInput } = filters;
  const { data, loading, error, refetch } = useQuery(DDI_HOME_REPORT_PIE_CHART, {
    variables: {
      input: filterInput,
    },
    skip,
  });

  return {
    reportPieChart: data?.ddiHomeReportPieChart,
    loading,
    error,
    refetch,
  };
};
