import { useLazyQuery } from '@apollo/client';

import {
  VariantValuesInput,
  ProductVariantValuesSelectQuery,
  ProductVariantValuesSelectQueryVariables,
} from '~anyx/common/graphql';

import {
  PRODUCT_VARIANT_VALUES_SELECT,
} from '../graphql';
import { useLoadVariantOptions } from '../useLoadVariantOptions';

export const useProductVariantValues = <T = string>({
  filters: input,
}: {
  filters?: VariantValuesInput;
}) => {
  const [queryVariants] = useLazyQuery(PRODUCT_VARIANT_VALUES_SELECT);

  const { loadOptions } = useLoadVariantOptions<
    ProductVariantValuesSelectQuery['variantValues']['items'][0]['name'],
    T,
    ProductVariantValuesSelectQuery,
    ProductVariantValuesSelectQueryVariables
  >({
    labelFormatter: (item) => item,
    valueFormatter: (item) => item as unknown as T,
    listQuery: {
      variables: {
        input: {
          ...input,
        },
      },
      lazyQuery: queryVariants,
    },
  });
  return {
    loadOptions,
  };
};
