import { useCallback, useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';

import { HAS_LOGISTICS_CHANNEL } from './graphql';

interface LogisticPermissionControlLazy {
  lazy?: false;
  skip?: boolean;
  storeId: string | null;
}

interface LogisticPermissionControl {
  lazy?: true;
  skip?: false;
  storeId?: string | null;
}

interface HookReturnType {
  hasLogisticsChannel: boolean;
  error: unknown;
  loading: boolean;
  fetchHasLogisticsChannel: (storeId?: string | null) => Promise<{
    hasLogisticsChannel: boolean;
  }>;
}

function useStoreLogisticPermissionsCode(params: LogisticPermissionControlLazy): HookReturnType;
function useStoreLogisticPermissionsCode(params: LogisticPermissionControl): HookReturnType;
function useStoreLogisticPermissionsCode({
  lazy = false,
  skip = false,
  storeId = null,
}: LogisticPermissionControlLazy | LogisticPermissionControl): ReturnType<
  typeof useStoreHasLogisticChannel
> {
  const [execute, { data, error, loading }] = useLazyQuery(HAS_LOGISTICS_CHANNEL);

  useEffect(() => {
    if (!skip && !lazy) {
      execute({
        variables: {
          storeId: storeId,
        },
      });
    }
    // once set to lazy, this should remain lazy
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [execute, storeId]);

  const fetchHasLogisticsChannel = useCallback(
    async (storeId?: string | null) => {
      const { data: result } = await execute({
        variables: {
          storeId: storeId,
        },
      });
      return {
        hasLogisticsChannel: result?.hasLogisticsChannel.boolean ?? false,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [execute]
  );

  const hasLogisticsChannel = data?.hasLogisticsChannel.boolean ?? false;

  return {
    hasLogisticsChannel,
    error,
    loading,
    fetchHasLogisticsChannel,
  };
}

export const useStoreHasLogisticChannel = useStoreLogisticPermissionsCode;
