import { useCallback, useMemo } from 'react';

import { Pagination, PaginationProps } from '@mantine/core';
import { tv } from 'tailwind-variants';

import { NumberUtils, useScreenBreakpoint } from '~anyx/shared/utils';

export interface TablePaginationProps extends Omit<PaginationProps, 'total'> {
  rowsPerPage: number;
  count: number;
  className?: string;
}

const classNameWrapper = tv({
  base: 'flex flex-col items-center justify-between gap-2 md:flex-row md:px-4 lg:px-0 py-3',
});

export const TablePagination = ({
  rowsPerPage,
  count,
  value: page = 1,
  className,
  onChange,
  ...rest
}: TablePaginationProps) => {
  const isMediumScreen = useScreenBreakpoint('md');
  const total = Math.ceil(count / rowsPerPage);

  const getRange = useCallback(() => {
    if (page < 1 || page > total) return;

    const startItem = (page - 1) * rowsPerPage + 1;
    let endItem = page * rowsPerPage;

    if (endItem > count) {
      endItem = count;
    }

    return { startItem, endItem };
  }, [count, page, rowsPerPage, total]);

  const itemIndexes = useMemo(() => getRange(), [getRange]);

  if (count === 0) return null;

  return (
    <div className={classNameWrapper({ className })}>
      <div className="text-gray-7">
        <span>{NumberUtils.format(itemIndexes?.startItem || 1)}</span> -{' '}
        <span>{itemIndexes?.endItem && NumberUtils.format(itemIndexes?.endItem)}</span>
        <span>{' / '}</span>
        <span>{NumberUtils.format(count)}</span>
      </div>
      <Pagination
        total={total}
        siblings={!isMediumScreen ? 0 : 1}
        gap={!isMediumScreen ? 4 : 8}
        onChange={onChange}
        value={page}
        getControlProps={(control) => {
          // control for next and previous buttons in mobile view
          if (!isMediumScreen && (control === 'next' || control === 'previous')) {
            return {
              style: {
                fontSize: '12px',
                paddingLeft: '6px',
                paddingRight: '6px',
                width: '64px',
                height: '32px',
              },
            };
          }
          return {};
        }}
        {...rest}
      />
    </div>
  );
};
