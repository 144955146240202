import { Trans, useTranslation } from 'react-i18next';
import { createSearchParams } from 'react-router';

import { useQuery } from '@apollo/client';

import { MarketplaceSaleChannelType } from '~anyx/common/graphql';
import { ProductListTab, IntegrationRoutePath, ProductRoutePath } from '~anyx/common/routing';
import { useWorkspaceCtx, WorkspaceMode } from '~anyx/common/workspace';
import { AnyXLanguage, DocUtils } from '~anyx/shared/utils';

import { SETTING_GUIDE_SALE_CHANNEL } from './graphql';

export interface SettingGuideItem {
  title: string;
  content: JSX.Element;
  isChecked: boolean;
  button: string;
  link?: {
    pathname: string;
    search?: string | null;
  };
}

export interface SettingGuideProps {
  hasSalesChannels: boolean;
  hasShopifyChannel: boolean;
  hasDatasourcesChannels: boolean;
  hasInputUnitCost: boolean;
  hasInputSaleChannelCostIndex: boolean;
}

export const useSettingGuideItems = ({
  hasSalesChannels,
  hasShopifyChannel,
  hasDatasourcesChannels,
  hasInputUnitCost,
  hasInputSaleChannelCostIndex,
}: SettingGuideProps) => {
  const { t } = useTranslation();
  const { workspace: { storeId = '' } = {}, mode } = useWorkspaceCtx();
  const integrationListLink = IntegrationRoutePath({
    singleStoreMode: mode === WorkspaceMode.ON,
    storeId,
  }).list().path;
  const salesChannelListLink = IntegrationRoutePath({
    singleStoreMode: mode === WorkspaceMode.ON,
    storeId,
  })
    .salesChannel()
    .select().path;
  const salesChannelDetailLink = (channelId = '', type = '') =>
    IntegrationRoutePath({
      storeId,
      singleStoreMode: mode === WorkspaceMode.ON,
    })
      .salesChannel()
      .details({ channelId, channelType: type }).path;
  const productListLink = ProductRoutePath().list().path;

  const { data: storeData, loading } = useQuery(SETTING_GUIDE_SALE_CHANNEL, {
    skip: !storeId,
    variables: {
      mdStoreId: storeId,
    },
  });

  const channelsWithoutCostIndex = (storeData?.MdStore?.channels || []).filter(
    (channel) => channel.type !== MarketplaceSaleChannelType.Manual && !channel.costIndex
  );

  const settingGuideItems: SettingGuideItem[] = [
    {
      title: t('core.page.home.settingGuide.connectSalesChannels.title', { ns: 'core' }),
      content: (
        <Trans
          i18nKey="core.page.home.settingGuide.connectSalesChannels.content"
          ns="core"
          components={[
            <a
              className="link-basic"
              rel="noreferrer"
              target="_blank"
              href={DocUtils.getGitbookLink(
                '/data-linkages/integrations-and-data-linkages/connection',
                {
                  [AnyXLanguage.JA_JP]: '/integrations/integrations/sales-channel',
                }
              )}
            >
              Link
            </a>,
          ]}
        />
      ),
      isChecked: hasSalesChannels,
      button: t('core.page.home.settingGuide.connectSalesChannels.button', { ns: 'core' }),
      link: {
        pathname: salesChannelListLink,
        search: null,
      },
    },
    // this option is shown only when there is shopify connection AXRP-1224
    ...(hasShopifyChannel
      ? [
          {
            title: t('core.page.home.settingGuide.connectAdsAndAnalytics.title', { ns: 'core' }),
            content: (
              <Trans
                i18nKey="core.page.home.settingGuide.connectAdsAndAnalytics.content"
                ns="core"
                components={[
                  <a
                    className="link-basic"
                    rel="noreferrer"
                    target="_blank"
                    href={DocUtils.getGitbookLink('/data-linkages/integrations-and-data-linkages', {
                      [AnyXLanguage.JA_JP]: '/integrations/integrations',
                    })}
                  >
                    Link
                  </a>,
                ]}
              />
            ),
            isChecked: hasDatasourcesChannels,
            button: t('core.page.home.settingGuide.connectAdsAndAnalytics.button', { ns: 'core' }),
            link: {
              pathname: integrationListLink,
              search: null,
            },
          },
        ]
      : []),
    {
      title: t('core.page.home.settingGuide.inputSalesChannelCost.title', { ns: 'core' }),
      content: (
        <>
          <Trans
            i18nKey="core.page.home.settingGuide.inputSalesChannelCost.content"
            ns="core"
            components={[
              <a
                className="link-basic"
                rel="noreferrer"
                target="_blank"
                href={DocUtils.getGitbookLink(
                  '/data-linkages/integrations-and-data-linkages/connection',
                  {
                    [AnyXLanguage.JA_JP]: '/integrations/integrations/cost/sales-channel',
                  }
                )}
              >
                Link
              </a>,
            ]}
          />
          {channelsWithoutCostIndex?.length ? (
            <span className="block">
              {t('core.page.home.settingGuide.inputSalesChannelCost.tooltip', {
                ns: 'core',
                value: channelsWithoutCostIndex?.length,
              })}
            </span>
          ) : null}
        </>
      ),
      isChecked: hasInputSaleChannelCostIndex,
      button: t('core.page.home.settingGuide.inputSalesChannelCost.button', { ns: 'core' }),
      link: {
        // Keep redirecting until there are no sales channels without cost index AXRP-1230
        ...(channelsWithoutCostIndex.length
          ? {
              pathname: salesChannelDetailLink(
                channelsWithoutCostIndex?.[0]?.id,
                channelsWithoutCostIndex?.[0]?.type
              ),
              search: createSearchParams({
                tab: 'integrations-details',
                saleChannelType: channelsWithoutCostIndex?.[0]?.type || '',
              }).toString(),
            }
          : {
              pathname: integrationListLink,
              search: null,
            }),
      },
    },
    {
      title: t('core.page.home.settingGuide.inputUnitCost.title', { ns: 'core' }),
      content: (
        <Trans
          i18nKey="core.page.home.settingGuide.inputUnitCost.content"
          ns="core"
          components={[
            <a
              className="link-basic"
              rel="noreferrer"
              target="_blank"
              href={DocUtils.getGitbookLink('/products/unit-cost', {
                [AnyXLanguage.JA_JP]: '/integrations/integrations/cost/merchandise-cost',
              })}
            >
              Link
            </a>,
          ]}
        />
      ),
      isChecked: hasInputUnitCost,
      button: t('core.page.home.settingGuide.inputUnitCost.button', { ns: 'core' }),
      link: {
        pathname: productListLink,
        search: createSearchParams({ viewType: ProductListTab.PRODUCTS }).toString(),
      },
    },
  ];

  return { settingGuideItems, loading };
};
