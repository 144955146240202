import { useQuery } from '@apollo/client';

import { HomeFilterInputFilterType } from '../components';
import { HOME_REPORT_CHART } from '../graphql';

interface UseHomeReportChartProps {
  filters: HomeFilterInputFilterType;
  skip: boolean;
}

export const useHomeReportChart = ({ filters, skip }: UseHomeReportChartProps) => {
  const { metric: _metric, ...filterInput } = filters;
  const { data, loading, error, refetch } = useQuery(HOME_REPORT_CHART, {
    variables: {
      filter: filterInput,
    },
    skip,
  });

  return {
    reportChart: data?.saleChannelReportChart,
    loading,
    error,
    refetch,
  };
};
