import { useQuery } from '@apollo/client';

import { DdiHomeFilterInputFilterType } from '../components';
import { DDI_HOME_REPORT_PRODUCT_TABLE } from '../graphql';

interface UseDdiHomeReportProductTableProps {
  filters: DdiHomeFilterInputFilterType;
  skip: boolean;
}

export const useDdiHomeReportProductTable = ({
  filters,
  skip,
}: UseDdiHomeReportProductTableProps) => {
  const { metric: _metric, ...filterInput } = filters;
  const { data, loading, error, refetch } = useQuery(DDI_HOME_REPORT_PRODUCT_TABLE, {
    variables: {
      input: filterInput,
    },
    skip,
  });

  return {
    reportProductTable: data?.ddiHomeReportTopProducts,
    loading,
    error,
    refetch,
  };
};
