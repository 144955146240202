import { gql } from '~anyx/common/graphql';

export const GET_TIMEZONES = gql(/* GraphQL */ `
  query MdTimezones {
    MdTimezones {
      updatedAt
      offset
      name
      id
      createdAt
    }
  }
`);
